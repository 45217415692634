.header {
  background-color: var(--surface-color);
  padding: 1rem 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.headerContent {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}

.brandContainer {
  display: flex;
  align-items: center;
}

.logoLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--on-surface);
}

.logo {
  margin-right: 0.5rem;
}

.brandName {
  font-size: 1.5rem;
  font-weight: bold;
}

.brandMessage {
  margin-left: 1rem;
  font-size: 0.9rem;
  color: var(--on-surface);
  opacity: 0.8;
}

.nav {
  flex-grow: 1;
  margin: 0 2rem;
}

.navLinks {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.navItem {
  margin-right: 1rem;
}

.navLink {
  color: var(--on-surface);
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.navLink:hover {
  color: var(--primary-color);
}

.logoutButton {
  background-color: var(--primary-color);
  color: var(--on-primary);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.logoutButton:hover {
  background-color: var(--accent-color);
}

.headerControls {
  display: flex;
  align-items: center;
}

.languageSelector {
  margin-right: 1rem;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid var(--on-surface);
  background-color: var(--surface-color);
  color: var(--on-surface);
}

.darkModeToggle {
  background-color: var(--surface-color);
  color: var(--on-surface);
  border: 1px solid var(--on-surface);
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.darkModeToggle:hover {
  background-color: var(--on-surface);
  color: var(--surface-color);
}

@media (max-width: 768px) {
  .headerContent {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav {
    margin: 1rem 0;
  }

  .navLinks {
    flex-direction: column;
  }

  .navItem {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }

  .headerControls {
    margin-top: 1rem;
  }
}
