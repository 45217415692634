.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.title {
  font-size: 2rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.profileInfo {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 20px;
}

.profileInfo p {
  margin-bottom: 10px;
}

.form {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 20px;
}

.formGroup {
  margin-bottom: 15px;
}

.formGroup label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

.formGroup input,
.formGroup textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.formGroup textarea {
  height: 100px;
  resize: vertical;
}

.submitButton,
.editButton {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submitButton:hover,
.editButton:hover {
  background-color: #45a049;
}

.cancelButton {
  padding: 10px 20px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-left: 10px;
}

.cancelButton:hover {
  background-color: #d32f2f;
}

.error {
  color: #d32f2f;
  text-align: center;
  margin-bottom: 10px;
}

.fieldError {
  color: #d32f2f;
  font-size: 0.8rem;
  margin-top: 5px;
  display: block;
}

@media (max-width: 480px) {
  .container {
    padding: 10px;
  }
}
