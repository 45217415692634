.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.title {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 20px;
  text-align: center;
}

/* Add any additional styles for the UploadPage component here */
