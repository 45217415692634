/* Global styles */
body {
  font-family: 'Roboto', Arial, sans-serif;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
}

/* Accessibility */
.skip-link {
  position: absolute;
  top: -40px;
  left: 0;
  background: var(--primary-color);
  color: var(--on-background);
  padding: 8px;
  z-index: 100;
  transition: top 0.3s ease;
}

.skip-link:focus {
  top: 0;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

/* Apply animations to main content */
main {
  animation: fadeIn 0.5s ease-out;
}

/* Apply animations to cards and list items */
.card, .listItem {
  animation: slideIn 0.5s ease-out;
}

/* Dark mode styles */
body.dark-mode {
  background-color: var(--background-color);
  color: var(--on-background);
}

body.dark-mode .App {
  background-color: var(--surface-color);
}

body.dark-mode a {
  color: var(--secondary-color);
}

body.dark-mode input,
body.dark-mode textarea,
body.dark-mode select {
  background-color: var(--surface-color);
  color: var(--on-surface);
  border-color: var(--on-surface);
}

body.dark-mode button {
  background-color: var(--primary-color);
  color: var(--on-background);
}

body.dark-mode button:hover {
  background-color: var(--accent-color);
}

/* Responsive design */
@media (max-width: 768px) {
  .App {
    padding: 0 10px;
  }
}
