.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.hero {
  background-size: cover;
  background-position: center;
  color: var(--on-background);
  text-align: center;
  padding: 150px 0;
  margin-bottom: 60px;
  position: relative;
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.heroContent {
  position: relative;
  z-index: 1;
}

.heroTitle {
  font-size: 4rem;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.heroSubtitle {
  font-size: 1.5rem;
  margin-bottom: 30px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.ctaButton {
  display: inline-block;
  background-color: var(--primary-color);
  color: var(--on-background);
  padding: 15px 30px;
  border-radius: 30px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.ctaButton:hover {
  background-color: var(--accent-color);
  transform: scale(1.05);
}

.featuredSection, .featuredArtistSection, .genresSection, .recentTracksSection {
  margin-bottom: 60px;
}

.sectionTitle {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: var(--on-background);
  text-align: center;
}

.cardGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 30px;
}

.card {
  background-color: var(--surface-color);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.cardImage {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.cardContent {
  padding: 20px;
}

.cardTitle {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: var(--on-surface);
}

.cardArtist {
  font-size: 0.9rem;
  color: var(--on-surface);
  opacity: 0.8;
  margin-bottom: 15px;
}

.cardLink {
  display: inline-block;
  background-color: var(--secondary-color);
  color: var(--on-background);
  padding: 8px 16px;
  border-radius: 20px;
  text-decoration: none;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
}

.cardLink:hover {
  background-color: var(--accent-color);
}

.featuredArtistCard {
  display: flex;
  background-color: var(--surface-color);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.featuredArtistImage {
  width: 40%;
  object-fit: cover;
}

.featuredArtistContent {
  padding: 30px;
  flex: 1;
}

.featuredArtistName {
  font-size: 2rem;
  margin-bottom: 15px;
  color: var(--on-surface);
}

.featuredArtistBio {
  font-size: 1rem;
  color: var(--on-surface);
  opacity: 0.8;
  margin-bottom: 20px;
}

.featuredArtistLink {
  display: inline-block;
  background-color: var(--primary-color);
  color: var(--on-background);
  padding: 10px 20px;
  border-radius: 25px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.featuredArtistLink:hover {
  background-color: var(--accent-color);
}

.trackList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.trackItem {
  background-color: var(--surface-color);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.trackImage {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.trackInfo {
  padding: 15px;
}

.trackInfo h4 {
  font-size: 1rem;
  margin-bottom: 10px;
  color: var(--on-surface);
}

.listenButton {
  display: inline-block;
  background-color: var(--secondary-color);
  color: var(--on-background);
  padding: 5px 10px;
  border-radius: 15px;
  text-decoration: none;
  font-size: 0.8rem;
  transition: background-color 0.3s ease;
}

.listenButton:hover {
  background-color: var(--accent-color);
}

.genreSection {
  margin-bottom: 40px;
}

.genreTitle {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: var(--on-background);
}

.joinSection {
  background-color: var(--primary-color);
  color: var(--on-background);
  text-align: center;
  padding: 60px 0;
  border-radius: 10px;
  margin-bottom: 60px;
}

.joinTitle {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.joinDescription {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.joinButton {
  display: inline-block;
  background-color: var(--on-background);
  color: var(--primary-color);
  padding: 15px 30px;
  border-radius: 30px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.joinButton:hover {
  background-color: var(--accent-color);
  color: var(--on-background);
  transform: scale(1.05);
}

.audioPlayer {
  width: 100%;
  margin-top: 10px;
}

.downloadButton {
  display: inline-block;
  background-color: var(--secondary-color);
  color: var(--on-background);
  padding: 5px 10px;
  border-radius: 15px;
  text-decoration: none;
  font-size: 0.8rem;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.downloadButton:hover {
  background-color: var(--accent-color);
}

.recentTracksSection {
  background-color: var(--surface-color);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .heroTitle {
    font-size: 3rem;
  }

  .heroSubtitle {
    font-size: 1.2rem;
  }

  .sectionTitle {
    font-size: 2rem;
  }

  .cardGrid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  .featuredArtistCard {
    flex-direction: column;
  }

  .featuredArtistImage {
    width: 100%;
    height: 200px;
  }

  .trackList {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  .recentTracksSection {
    padding: 20px;
  }
}
