.container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
}

.title {
  font-size: 2rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.form {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 20px;
}

.formGroup {
  margin-bottom: 15px;
}

.formGroup label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

.formGroup input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.submitButton {
  width: 100%;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submitButton:hover {
  background-color: #45a049;
}

.error {
  color: #d32f2f;
  text-align: center;
  margin-bottom: 10px;
}

.fieldError {
  color: #d32f2f;
  font-size: 0.8rem;
  margin-top: 5px;
  display: block;
}

.registerLink {
  text-align: center;
  margin-top: 15px;
}

.registerLink a {
  color: #1a73e8;
  text-decoration: none;
}

.registerLink a:hover {
  text-decoration: underline;
}

@media (max-width: 480px) {
  .container {
    padding: 10px;
  }
}
